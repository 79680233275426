// import * as pcj from '../../../package.json';

export const environment = {
  production: true,
  baseUrl: 'https://api.badkuche.de/',
  publicUrl: '',
  apiUrl: 'v1/',
  siteUrl: 'https://badkuche.de/'
  // version: pcj.version,
};
